import React, { useState } from 'react';
import { MdPhone } from "react-icons/md";
import { NavLink } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
    window.scrollTo(0, 0); // Scroll to top when a menu item is clicked
  };
  return (
    <header className="text-gray-600 manrope-regular bg-white border-t-[5px] border-red-400 shadow-md sticky top-0 z-[99]">
     <ScrollToTop/>
    <div className="container mx-auto flex flex-wrap justify-between p-5 py-5 flex-row md:flex-row items-center">
      <div onClick={toggleMenu} className='flex lg:hidden'> 
      {isOpen ? (
        <div className={`flex items-center justify-between z-[9999] relative bg-red-400  p-[15px] border-b border-gray-200 min-w-[300px] ${isOpen ? 'translate-x-0 transition-all ease-in-out -mx-5 -mt-6' : 'translate-x-[-110%] transition-all ease-in-out -mx-5 -mt-6 md:mx-0'}`}>
          <h2 className='text-xl title-font text-white font-bold uppercase'>Menu</h2>
          <div className="w-[20px] h-[20px] flex justify-center items-center  relative cursor-pointer">
            <span className='absolute w-full h-[4px] bg-white rounded-[2px] rotate-45'></span>
            <span className='absolute w-full h-[4px] bg-white rounded-[2px] rotate-[-45deg]'></span>
          </div>
          </div>
        ) : (
      <div className="w-[24px] h-[20px] flex lg:hidden flex-col  z-[999] justify-between cursor-pointer">
        <div className="w-full h-[4px] bg-black/60"></div>
        <div className="w-full h-[4px] bg-black/60"></div>
        <div className="w-full h-[4px] bg-black/60"></div>
      </div>
      )}
      </div>
        <a href='/' className="flex title-font font-medium items-center text-gray-900 mb-0  md:mb-0 mx-auto lg:mx-0">
            <img src={`${process.env.PUBLIC_URL}/tech-e-spark-logo.png`} width={220} height={80} className='w-[180px] md:w-[250px] h-auto' alt="Techespark Logo"/>
        </a>
        <ul className={`md:ml-auto none flex-wrap items-center text-base justify-start lg:justify-center manrope-semibold font-semibold text-[16px] gap-x-8 fixed lg:relative top-0 flex lg:translate-x-0 order-1 lg:order-none h-[100vh] lg-h-auto ${isOpen ? 'flex flex-col h-[100vh] pt-[60px] bg-white py-[10px] w-[330px] top-0 left-0 translate-x-0 shadow-xl transition-all ease-in-out' : 'transition-all ease-in-out translate-x-[-110%] h-[100vh] lg:h-auto'}`}>
          <li className='w-full lg:w-auto flex'><NavLink to="/" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 sm:w-full' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 sm:w-full'} onClick={closeMenu}>Home</NavLink></li>
          <li className='w-full lg:w-auto flex'><NavLink to="/about" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 sm:w-full sm:table' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 sm:w-full sm:table'} onClick={closeMenu}>About Us</NavLink></li>
          <li className='relative group w-full lg:w-auto'><NavLink to="/services" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 lg:h-[50px] table lg:leading-[50px] transition-all ease-in-out' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 lg:h-[50px] table lg:leading-[50px]  transition-all ease-in-out'} onClick={closeMenu}>Services</NavLink>
              <ul className='lg:hidden lg:group-hover:table lg:absolute lg:top-[45px] lg:right-0 bg-white lg:drop-shadow-xl lg:border lg:border-gray-200'>
                <li className='bg-white w-full'><NavLink to="/services/graphic-design-service" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200 p-3  lg:px-4 whitespace-nowrap block ' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap block'} onClick={closeMenu}><span className='text-red-400 mr-1'>&mdash;</span> Graphic Design Service</NavLink></li>
                <li className='bg-white w-full'><NavLink to="/services/website-design-development" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap block' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap block'} onClick={closeMenu}><span className='text-red-400 mr-1'>&mdash;</span> Website Design & Development</NavLink></li>
                <li className='bg-white w-full'><NavLink to="/services/digital-marketing-service" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap block' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap  block'} onClick={closeMenu}><span className='text-red-400 mr-1'>&mdash;</span> Digital Marketing Service</NavLink></li>
                <li className='bg-white w-full'><NavLink to="/services/ecommerce-development-service" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap block' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200  p-3 lg:px-4 whitespace-nowrap  block'} onClick={closeMenu}><span className='text-red-400 mr-1'>&mdash;</span> eCommerce Development</NavLink></li>
              </ul>
          </li>
          <li className='w-full lg:w-auto flex'><NavLink to="/portfolio" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 sm:w-full sm:table' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 sm:w-full sm:table'} onClick={closeMenu}>Portfolio</NavLink></li>
          <li className='w-full lg:w-auto flex'><NavLink to="/contact" className={({ isActive }) => isActive ? 'text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 lg:hidden sm:w-full sm:table' : 'text-gray-500 hover:text-red-400  w-full lg:w-auto border-b border-gray-200 lg:border-none p-3 lg:p-0 lg:hidden sm:w-full sm:table'} onClick={closeMenu}>Contact Us</NavLink></li>
        </ul>
        <div className='hidden lg:flex flex-row gap-5 ml-auto'>
          <div className='flex flex-row items-center gap-x-2 py-2 px-2 h-[50px] border border-gray-300 rounded-[40px] bg-white'>
            <span className='w-9 h-9 bg-red-400 rounded-[20px] flex items-center justify-center '><MdPhone  size={20} color='#fff'/></span>
            <div className='flex flex-col mr-2'>
              <span className='text-xs leading-[14px] table'>WhatsApp Number</span>
              <strong className='text-[14px] leading-[18px]'>(+91) 99597 90204</strong>
            </div>
          </div>
          <NavLink to="/contact" className={({ isActive }) => isActive ? 'bg-red-500 px-5 py-2 text-white items-center justify-center font-bold rounded-[25px] hidden lg:flex' : 'px-5 py-2 bg-red-400 text-white  items-center justify-center font-bold rounded-[25px] text-xs md:text-base hover:bg-red-500 hidden lg:flex'}>Contact Us</NavLink>
        </div>
    </div>
</header>
  );
};

export default Header;